import { Component, Inject, Watch, Vue, Prop } from "vue-property-decorator";
import { CONTAINER_TYPES } from "../../infrastructure/ioc/container-types";
import ServiceInterface from "@/vue/service/service-interface";
import Utils from "@/vue/infrastructure/utils/helpers";
import DfProduct from "@/vue/domain/product/df-product";
import DfPromotion from "@/vue/domain/promotion/df-promotion";
import DfCategory from "@/vue/domain/category/df-category";
import DfPaging from "@/vue/domain/paging/df-paging";
import DfContent from "@/vue/domain/content/df-content";
import DfBrand from "@/vue/domain/brand/df-brand";

const DfCardProductComponent = () => Utils.externalComponent2("df-card-product");
const DfCardBannerComponent = () => Utils.externalComponent2("df-card-banner");

const lazyLoadingEnabled = process.env.VUE_APP_LAZY_LOADING_ENABLED;

@Component({
  components: {
    DfCardProduct: DfCardProductComponent,
    DfCardBanner: DfCardBannerComponent,
  },
})
export default class DfSectionProductsComponent extends Vue {
  @Inject(CONTAINER_TYPES.ServiceInterface)
  private service!: ServiceInterface;

  @Prop({ type: Object, default: null })
  promotion: DfPromotion;

  private lazyLoadingEnabled = true;

  beforeMount() {
    this.lazyLoadingEnabled = lazyLoadingEnabled === "true";
  }

  get selectedTags(): Array<DfCategory> {
    return this.$store.getters.activeTags;
  }

  get selectedCategory(): Array<DfCategory> {
    return this.$store.getters.activeCategory;
  }

  get productsQueryValue(): string {
    return this.$store.getters.productsQueryValue;
  }

  get products(): Array<DfProduct> {
    return this.$store.getters.products;
  }

  get banners(): Array<DfContent> {
    return this.$store.getters.promotionContents.filter((content: DfContent) => content.type.code === "BAN");
  }

  get bannersByPosition(): Array<DfContent> {
    return this.banners.filter((banner: DfContent) => {
      // TODO: Eliminare a inizio 2023
      const valueString: string = Utils.getPropertyValue(banner, Utils.PROPERTY_POSITION, "STRING");
      const valueList: any = Utils.getPropertyValue(banner, Utils.PROPERTY_POSITION, "LIST", {});

      return valueString === Utils.PROPERTY_BANNER_POSITION_PRODUCTS_LIST || valueList.key === "ALL" || valueList.key === Utils.PROPERTY_BANNER_POSITION_PRODUCTS_LIST;
    });
  }

  get elements(): Array<DfProduct | DfContent> {
    const elements: Array<DfProduct | DfContent> = [...this.products];
    this.bannersByPosition.forEach((banner: DfContent) => {
      if (banner.priority <= elements.length) {
        elements.splice(banner.priority, 0, banner);
      }
    });
    return elements;
  }

  get customFiltersQuery(): Array<string> {
    return this.$store.getters.customFiltersQuery;
  }

  get isSearchActive(): boolean {
    const rule01: boolean = this.selectedTags.length > 0;
    const rule02: boolean = this.productsQueryValue && this.productsQueryValue.length > 0;
    const rule03: boolean = this.selectedCategory != null;
    const rule04: boolean = this.customFiltersQuery.length > 0;
    return rule01 || rule02 || rule03 || rule04;
  }

  get productsPaging(): DfPaging {
    return this.$store.getters.productsPaging;
  }

  get pagingPercentage() {
    return 100 * (this.products.length / this.productsPaging.totalElements);
  }

  get brand(): DfBrand {
    return this.$store.getters.brand;
  }

  get showProductsCounter(): boolean {
    return Utils.getPropertyValue(this.brand, Utils.PROPERTY_BRAND_SHOW_PRODUCTS_COUNTER, "BOOLEAN", true);
  }

  private isBanner(element: DfProduct | DfContent): boolean {
    return element instanceof DfContent;
  }
}